
/***************************RESET CSS TO MAKE IT CONSISTENT ACROSS BROWSERS*********************************************/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body {
    line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

    nav ul {
        list-style: none;
    }

blockquote, q {
    quotes: none;
}

    blockquote:before, blockquote:after, q:before, q:after {
        content: '';
        content: none;
    }

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

/* change colors to suit your needs */
ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

/* change colors to suit your needs */
mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* change border color to suit your needs */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

/**********************************************************************************************************************/

body {
    overflow:hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.full-screen {
    position: absolute;
    width: 100%;
    height: 100%;
}
.center_buttons {
    display: flex;
    justify-content:center;
}

.cloth_img {
    position: absolute;
    top: 15px;
    z-index: 1;
    background-size: contain;
    background-color: lightsteelblue;
    border:solid;
    border-radius:8px;
    color: black;
    font-size:18px;
    font-family: 'Times New Roman', Times, serif;
}
    .cloth_img:hover {
        opacity: 0.8;
    }

.popup_main {
    display: none;
}

.popup {
    position: relative;
    top: 10px;
    display:block;
    margin: 0 auto;
    text-align: center;
    z-index: 3;
}

.blocker {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    background: rgba(0,0,0,.5);
}


.subscrips {
    position: absolute;
    top: 50px;
    z-index: 1;
    background-size: contain;
    background-color: lightsteelblue;
    border: solid;
    border-radius: 8px;
    font-size: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}
    .subscrips:hover {
        opacity: 0.8;
    }

.fetch_clothes {
    position: absolute;
    top: 85px;
    z-index: 1;
    background-size: contain;
    background-color: lightsteelblue;
    border: solid;
    border-radius: 8px;
    font-size: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}
    .fetch_clothes:hover {
        opacity: 0.8;
    }

.delete_cloth {
    position: absolute;
    top: 10px;
    width: 40px;
    height: 40px;
    margin-right: 265px;
    background-image: url("delete.png");
    background-size: contain;
    background-color: transparent;
    border: none;
}
    .delete_cloth:hover {
        opacity: 0.8;
    }

.cancel_sub {
    background-size: contain;
    background-color: dodgerblue;
    color: white;
    border: solid;
    border-radius: 8px;
    border:groove;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
}
    .cancel_sub:hover {
        opacity: 0.8;
    }

/* Full-width input fields */
input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

/* Set a style for all buttons */
.userLogins {
    position: absolute;
    top: 55px;
    margin-right: 285px;
    background-size: contain;
    background-color: lightseagreen;
    border: solid;
    border-radius: 8px;
    font-size: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}

    .userLogins:hover {
        opacity: 0.8;
    }

/* Extra styles for the cancel button */
/*.cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
}*/

.container {
    padding: 16px;
    border-radius: 10px;
}

span.email {
    float: right;
    padding-top: 16px;
}

.modal_main {
    display: none;
}
/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 60px;
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border-radius: 10px;
    width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
}

    .close:hover,
    .close:focus {
        color: red;
        cursor: pointer;
    }

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.email {
        display: block;
        float: none;
    }

    .cancelbtn {
        width: 100%;
    }
}


.img_returned {
    position: absolute;
    bottom: 150px;
    right: 20px;
}

.camera-switch {
    position: absolute;
    width: 55px;
    height: 55px;
    top: 40px;
    right: 15px;
    z-index: 1;
    background-image: url("switch.png");
    background-size: contain;
    background-color: transparent;
    border: none;
}
    .camera-switch:hover {
        opacity: 0.8;
    }

.help {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 5px;
    right: 10px;
    z-index: 1;
    background-image: url("help_btn.png");
    background-size: contain;
    background-color: transparent;
    border: none;
}
    .help:hover {
        opacity: 0.8;
    }

.sendtoDB {
    border: solid;
    border-width: thin;
    border-radius: 8px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
}

    .sendtoDB:hover {
        opacity: 0.8;
    }

.avatar-switch {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 64px;
    right: 8px;
    z-index: 1;
    background-image: url("avatar.png");
    background-size: contain;
    background-color: transparent;
    border: none;
}

.img_upload {
    position: absolute;
    top: 65px;
    right: 45px;
}

.shirt_out {
    top: 54px;
    right: 8px;
}

.pants_out {
    top: 54px;
    right: 4px;
}

.avatar-switch:checked {
    background-image: url("outfit.png");
}

input.avatar-switch[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
}

.record {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 8px;
    left: 8px;
    z-index: 1;
    background-image: url("snapshot.png");
    background-size: contain;
    background-color: transparent;
    border: none;
}

.carousel {
    position: absolute;
    width: 100%;
    height: 64px;
    bottom: 16px;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
}

.shirt_out {
    position: absolute;
    right: 13px;
    top: 170px;
}

.pants_out {
    position: absolute;
    right: 30px;
    top: 130px;
}

.outline {
    position: absolute;
    width: 100%;
    height: 64px;
    bottom: 90px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.outline-item {
    background-size: contain;
    background-color: transparent;
    border: double 4px #008080;
    border-radius: 50%;
    overflow: hidden;
    height: 64px;
    width: 64px;
    margin: auto 4px;
    transition: .6s;
}

.outline input[type="radio"] {
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
}

.outline-item:checked {
    border: solid 4px #008080;
}

.carousel-item {
    background-size: contain;
    background-color: transparent;
    border: double 4px #008080;
    border-radius: 50%;
    overflow: hidden;
    height: 64px;
    width: 64px;
    margin: auto 4px;
    transition: .6s;
    flex: 0 0 auto;
}

.carousel input[type="radio"] {
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
}

.carousel-item:checked {
    border: solid 4px #008080;
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #709dff 0%, #91fdb7 100%);
    z-index: 2;
}

    .dots > div {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 2px;
        border-radius: 100%;
        background-color: #008080;
        animation: dot-pulse 1.4s infinite ease-in-out both;
        -webkit-animation: dot-pulse 1.4s infinite ease-in-out both;
    }

    .dots .pulse1 {
        animation-delay: -0.32s;
        -webkit-animation-delay: -0.32s;
    }

    .dots .pulse2 {
        animation-delay: -0.16s;
        -webkit-animation-delay: -0.16s;
    }

@keyframes dot-pulse {
    0%, 80%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@-webkit-keyframes dot-pulse {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

.spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    animation: spinner 2.5s infinite linear both;
    -webkit-animation: spinner 2.5s infinite linear both;
}

.spinner-dot {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: spinner-dot 2.0s infinite ease-in-out both;
    -webkit-animation: spinner-dot 2.0s infinite ease-in-out both;
}

    .spinner-dot:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: #008080;
        border-radius: 100%;
        animation: spinner-dot-before 2.0s infinite ease-in-out both;
        -webkit-animation: spinner-dot-before 2.0s infinite ease-in-out both;
    }

    .spinner-dot:nth-child(1) {
        animation-delay: -1.1s;
    }

    .spinner-dot:nth-child(2) {
        animation-delay: -1.0s;
    }

    .spinner-dot:nth-child(3) {
        animation-delay: -0.9s;
    }

    .spinner-dot:nth-child(4) {
        animation-delay: -0.8s;
    }

    .spinner-dot:nth-child(5) {
        animation-delay: -0.7s;
    }

    .spinner-dot:nth-child(6) {
        animation-delay: -0.6s;
    }

    .spinner-dot:nth-child(1):before {
        animation-delay: -1.1s;
    }

    .spinner-dot:nth-child(2):before {
        animation-delay: -1.0s;
    }

    .spinner-dot:nth-child(3):before {
        animation-delay: -0.9s;
    }

    .spinner-dot:nth-child(4):before {
        animation-delay: -0.8s;
    }

    .spinner-dot:nth-child(5):before {
        animation-delay: -0.7s;
    }

    .spinner-dot:nth-child(6):before {
        animation-delay: -0.6s;
    }

.spinner-container {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin: auto;
    z-index: 1;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

@keyframes spinner {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner-dot {
    80%, 100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner-dot {
    80%, 100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner-dot-before {
    50% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
    }

    100%, 0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@-webkit-keyframes spinner-dot-before {
    50% {
        -webkit-transform: scale(0.4);
    }

    100%, 0% {
        -webkit-transform: scale(1.0);
    }
}
